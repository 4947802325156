import HttpService from '@/services/HttpService'
import ENDPOINTS from '@/services/endpoints'


class SexService {
  loadSexes = async () => { return (await HttpService.client.get(ENDPOINTS.SEX)).data };
  deleteSex = async (payload) => { await HttpService.client.delete(ENDPOINTS.SEX + payload) };
  createSex = async (payload) => { await HttpService.client.post(ENDPOINTS.SEX, payload) }; 
  editSex = async (payload) => { await HttpService.client.put(ENDPOINTS.SEX + payload.id, payload) }; 
}

const sexService = new SexService();

export default sexService;