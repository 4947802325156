<template>
  <v-container fill-height fluid>
    <v-container class="d-flex justify-center">

    <div class="d-flex flex-column mb-6">
      <v-card class="pa-2 d-flex justify-center flex-wrap ">
        <h1 class="display-4">Budo ID</h1>
      </v-card>
      <v-card class="pa-2">
        <v-text-field v-model="pin"  type="password" label="Enter pin..." hint="Please enter pin with button panel below" readonly></v-text-field>
      </v-card>
      <v-card class="pa-2">
        <v-row>
          <v-col><v-btn block :disabled="keyboarDisabled" v-on:click="enter(1)">一</v-btn></v-col>
          <v-col><v-btn block :disabled="keyboarDisabled" v-on:click="enter(2)">二</v-btn></v-col>
          <v-col><v-btn block :disabled="keyboarDisabled" v-on:click="enter(3)">三</v-btn></v-col>
        </v-row>
        <v-row>
          <v-col><v-btn block :disabled="keyboarDisabled" v-on:click="enter(4)">四</v-btn></v-col>
          <v-col><v-btn block :disabled="keyboarDisabled" v-on:click="enter(5)">五</v-btn></v-col>
          <v-col><v-btn block :disabled="keyboarDisabled" v-on:click="enter(6)">六</v-btn></v-col>
        </v-row>
        <v-row>
          <v-col><v-btn block :disabled="keyboarDisabled" v-on:click="enter(7)">七</v-btn></v-col>
          <v-col><v-btn block :disabled="keyboarDisabled" v-on:click="enter(8)">八</v-btn></v-col>
          <v-col><v-btn block :disabled="keyboarDisabled" v-on:click="enter(9)">九</v-btn></v-col>
        </v-row>
        <v-row>
          <v-col><v-btn color="warning" block v-on:click="clear">Clear</v-btn></v-col>
          <v-col><v-btn block :disabled="keyboarDisabled" v-on:click="enter(0)">零</v-btn></v-col>
          <v-col><v-btn color="success" block v-on:click="enterPin" :disabled="enterDisabled">Enter</v-btn></v-col>
        </v-row>
      </v-card>
    </div>
    </v-container>
  </v-container>
</template>

<script>
export default {
  name: "pin-view",
  data(){
      return {
          pin: ""
      }
  },
  computed: {
    pinMask(){
      return this.pin.replace(/./g, '*')
    },
    keyboarDisabled(){
      return this.pin.length > 9
    },
    enterDisabled(){
      return this.pin.length < 4
    }
  },  
  methods: {
    enter(character){
      this.pin = this.pin + character
    },
    clear(){
      this.pin = ''
    },
    enterPin(){
      let enter_pin_payload = {"pin": this.pin}
      this.$store.dispatch('loginByPin', enter_pin_payload).then(() =>{ this.$router.push({ name: 'users'}) })
    },
  },
}
</script>

<style scoped>
/* 
.btn{
    margin-bottom: 1rem;
} */
</style>