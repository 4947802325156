import HttpService from '@/services/HttpService'
import ENDPOINTS from '@/services/endpoints'


class GradeService {
  loadGrades = async () => { return (await HttpService.client.get(ENDPOINTS.GRADE)).data };
  deleteGrade = async (payload) => { await HttpService.client.delete(ENDPOINTS.GRADE + payload) };
  createGrade = async (payload) => { await HttpService.client.post(ENDPOINTS.GRADE, payload) }; 
  editGrade = async (payload) => { await HttpService.client.put(ENDPOINTS.GRADE + payload.id, payload) }; 
}

const gradeService = new GradeService();

export default gradeService;