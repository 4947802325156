import SexService from "@/services/SexService"

const state = {
    sexes: [],
  }
  
const getters = {
    sexes(state) { return state.sexes },
}

const mutations = {
    reloadSexes(state, payload) {
        if (payload) {
            state.sexes = payload
        }
    },
}

const actions = {
    async reloadSexes({ commit }) {
        const sexes = await SexService.loadSexes()
        commit('reloadSexes', sexes)
    },
    async deleteSex({ commit }, payload) {
        await SexService.deleteSex(payload)
        const sexes = await SexService.loadSexes()
        commit('reloadSexes', sexes)
    },
    async editSex({ commit }, payload) {
        await SexService.editSex(payload)
        const sexes = await SexService.loadSexes()
        commit('reloadSexes', sexes)
    },
    async createSex({ commit }, payload) {
        await SexService.createSex(payload)
        const sexes = await SexService.loadSexes()
        commit('reloadSexes', sexes)
    },
}

export default {
    state,
    getters,
    mutations,
    actions
}
