import HttpService from '@/services/HttpService'
import ENDPOINTS from '@/services/endpoints'


class OrganizationService {
  loadOrganizations = async () => { return (await HttpService.client.get(ENDPOINTS.ORGANIZATION)).data };
  deleteOrganization = async (payload) => { await HttpService.client.delete(ENDPOINTS.ORGANIZATION + payload) };
  createOrganization = async (payload) => { await HttpService.client.post(ENDPOINTS.ORGANIZATION, payload) }; 
  editOrganization = async (payload) => { await HttpService.client.put(ENDPOINTS.ORGANIZATION + payload.id, payload) }; 
}

const organizationService = new OrganizationService();

export default organizationService;