import HttpService from '@/services/HttpService'
import ENDPOINTS from '@/services/endpoints'

const config = {headers: {'Content-Type': "multipart/form-data; charset=utf-8; boundary=" + Math.random().toString().substr(2)}};

class ProfileImageService {
  uploadProfileImage = async (payload) => { 
    console.log("serivec. payload: ", payload)
    await HttpService.client.put(ENDPOINTS.UPLOAD_PROFILE_IMAGE + payload.profileId, payload.formData, config)
  };
  deleteProfileImage = async (payload) => { await HttpService.client.delete(ENDPOINTS.PROFILE_IMAGE + payload) };
}

const profileImageService = new ProfileImageService();

export default profileImageService;