import HttpService from '@/services/HttpService'
import ENDPOINTS from '@/services/endpoints'
import store from '@/store'


class AuthService {

  loginByPin = async payload => {
    const loginResponse = await HttpService.client.post(ENDPOINTS.LOGIN_BY_PIN, payload)
    const user = loginResponse.data
    return user;
  };

  loginByGoogleAuth = async payload => {
    const loginResponse = await HttpService.client.post(ENDPOINTS.LOGIN_BY_GOOGLE_AUTH, payload)
    const user = loginResponse.data
    return user;
  };

  getUser = () => { 
    return store.getters.getUser 
  }

  getAccessToken = () => {
    const user = this.getUser()
    const token = user ? user.access_token : undefined
    return token
  }

  getRefreshToken = () => {
    const user = this.getUser()
    const token = user ? user.refresh_token : undefined
    return token
  }

  isLoggedIn = () => {
    let isLoggedIn = false
    if (this.getAccessToken()) {
      isLoggedIn = true
    }
    return isLoggedIn
  }
}

const authService = new AuthService();

export default authService;