<template>
  <v-container>
    <v-form ref="profileForm" v-model="valid" lazy-validation @submit.prevent="editEntity" v-if="profile != null">
      <v-row>
        <v-col cols="2" sm="2" md="2">
          <v-text-field :rules="required_rules" required v-model="profile.ekf_code" label="EF Code"/>
        </v-col>
        <v-col cols="10" sm="4" md="4">
          <v-select :rules="[v => !!v || 'Item is required']" v-model="profile.organization_id" :items="organizations" item-text="name_en" item-value="id" label="Organization" />
        </v-col>
        <v-col cols="6" sm="3" md="3">
          <v-select :rules="[v => !!v || 'Item is required']" v-model="profile.sex_id" :items="sexes" item-text="name_en" item-value="id" label="Sex" />
        </v-col>
        <v-col cols="6" sm="3" md="3">
          <v-select :rules="[v => !!v || 'Item is required']" v-model="profile.city_id" :items="cities" item-text="name_en" item-value="id" label="City" />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="4" md="4">
          <v-text-field :rules="required_rules" required v-model="profile.first_name_en" label="first_name_en"/>
        </v-col>
        <v-col cols="12" sm="4" md="4">
          <v-text-field :rules="required_rules" required v-model="profile.last_name_en" label="last_name_en"/>
        </v-col>
        <v-col cols="12" sm="4" md="4">
          <v-text-field v-model="profile.middle_name_en" label="middle_name_en"/>
        </v-col>
        <v-col cols="12" sm="4" md="4">
          <v-text-field :rules="required_rules" required v-model="profile.first_name_ua" label="first_name_ua"/>
        </v-col>
        <v-col cols="12" sm="4" md="4">
          <v-text-field :rules="required_rules" required v-model="profile.last_name_ua" label="last_name_ua"/>
        </v-col>
        <v-col cols="12" sm="4" md="4">
          <v-text-field :rules="required_rules" required v-model="profile.middle_name_ua" label="middle_name_ua"/>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="5" sm="4" md="4">
          <v-select v-model="profile.current_kendo_grade_id" :items="grades" item-text="name_en" item-value="id" label="Kendo Grading" />
        </v-col>
        <v-col cols="5" sm="6" md="6">
          <v-menu v-model="kendoGradingDateMenu" :disabled="profile.current_kendo_grade_id == null" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="auto">
            <template v-slot:activator="{ on, attrs }">
              <v-text-field v-model="profile.current_kendo_grading_date" label="Kendo Grading Date" prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"></v-text-field>
            </template>
            <v-date-picker v-model="profile.current_kendo_grading_date" @input="kendoGradingDateMenu = false"></v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="2" sm="2" md="2">
          <v-icon large :disabled="profile.current_kendo_grade_id == null" @click="clearKendoGrading()">mdi-note-minus</v-icon>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="5" sm="4" md="4">
          <v-select v-model="profile.current_iaido_grade_id" :items="grades" item-text="name_en" item-value="id" label="Iaido grading" />
        </v-col>
        <v-col cols="5" sm="6" md="6">
          <v-menu v-model="iaidoGradingDateMenu" :disabled="profile.current_iaido_grade_id == null" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="auto">
            <template v-slot:activator="{ on, attrs }">
              <v-text-field v-model="profile.current_iaido_grading_date" label="Iaido Grading Date" prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"></v-text-field>
            </template>
            <v-date-picker v-model="profile.current_iaido_grading_date" @input="iaidoGradingDateMenu = false"></v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="2" sm="2" md="2">
          <v-icon large :disabled="profile.current_iaido_grade_id == null" @click="clearIaidoGrading()">mdi-note-minus</v-icon>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="5" sm="4" md="4">
          <v-select v-model="profile.current_jodo_grade_id" :items="grades" item-text="name_en" item-value="id" label="Jodo Grading" />
        </v-col>
        <v-col cols="5" sm="6" md="6">
          <v-menu v-model="jodoGradingDateMenu" :disabled="profile.current_jodo_grade_id == null" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="auto">
            <template v-slot:activator="{ on, attrs }">
              <v-text-field v-model="profile.current_jodo_grading_date" label="Jodo Grading Date" prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"></v-text-field>
            </template>
            <v-date-picker v-model="profile.current_jodo_grading_date" @input="jodoGradingDateMenu = false"></v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="2" sm="2" md="2">
          <v-icon large :disabled="profile.current_jodo_grade_id == null" @click="clearJodoGrading()">mdi-note-minus</v-icon>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="6" md="6">
          <v-text-field v-model="profile.additional_info_en" label="additional_info_en"/>
        </v-col>
        <v-col cols="12" sm="6" md="6">
          <v-text-field v-model="profile.additional_info_ua" label="additional_info_en"/>
        </v-col>
      </v-row>
      <v-row>
        <v-img v-if="profile.profile_image"
          :lazy-src="profile.profile_image.url"
          max-height="150"
          max-width="250"
          :src="profile.profile_image.url"
        ></v-img>
      </v-row>

      <v-row>
        <v-col cols="8" sm="8" md="8">
          <v-file-input
            v-model="file"
            label="Profile Image"
            placeholder="Select profile image"
            prepend-icon="mdi-account-box-outline"
            outlined
            :show-size="1000"
          >
          </v-file-input>
        </v-col>
        <v-col cols="4" sm="4" md="4">
          <v-btn @click="uploadImage" :disabled="file == null">Upload</v-btn>
        </v-col>
      </v-row>      
      <v-btn color="primary" :disabled="!valid" type="submit">Update</v-btn>
      <v-btn color="secondary" :to="{name: 'profiles'}">Cancel</v-btn>
      
    </v-form>
  </v-container>
</template>
  
<script>
export default {
    name: "createDraftStoreItem",
    data () {
      return {
        file: null,
        valid : false,
        required_rules: [
          v => !!v || 'Required',
          v => ( !!v && v.length <= 255) || 'Original name must be less than 255 characters',
        ],

        profile: null,
        kendoGradingDateMenu : false,
        jodoGradingDateMenu : false,
        iaidoGradingDateMenu : false,
      }
    },
    computed: {
      organizations(){
        return this.$store.getters.organizations
      },
      grades(){
        return this.$store.getters.grades
      },
      sexes(){
        return this.$store.getters.sexes
      },
      cities(){
        return this.$store.getters.cities
      },
    },
    methods: {
      initialize () {
        console.log("loading. param id: ", this.$route.params.id)
        this.$store.dispatch("reloadSexes")
        this.$store.dispatch("reloadOrganizations")
        this.$store.dispatch("reloadGrades")
        this.$store.dispatch("reloadCities")
        this.loadEntity(this.$route.params.id)
      },
      editEntity(){
        this.$store.dispatch('editProfile', this.profile).then(() => {
          this.$router.push({ name: 'profiles'})
        })
      },
      loadEntity(profile_id){
        console.log("profile view. loadEntity method. profile_id: ", profile_id)
        this.$store.dispatch('loadProfile', profile_id).then((result) => {
          this.profile = result
          console.log("current profile: ", this.profile)
        })
      },
      clearKendoGrading(){
        this.profile.current_kendo_grade_id = null
        this.profile.current_kendo_grading_date = null
      },
      clearIaidoGrading(){
        this.profile.current_iaido_grade_id = null
        this.profile.current_iaido_grading_date = null
      },
      clearJodoGrading(){
        this.profile.current_jodo_grade_id = null
        this.profile.current_jodo_grading_date = null
      },
      uploadImage(){
        const formData = new FormData();
        formData.append('files', this.file);
        const payload = {
            "profileId": this.profile.id,
            "formData": formData
        }
        console.log("payload:", payload)
        this.$store.dispatch('uploadProfileImage', payload).then(()=>{
          this.file = null
          this.initialize()
        })
      },
    },
    created() {
      this.initialize()
    }
  }
</script>
