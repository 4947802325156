import GradeService from "@/services/GradeService"

const state = {
    grades: [],
  }
  
const getters = {
    grades(state) { return state.grades },
}

const mutations = {
    reloadGrades(state, payload) {
        if (payload) {
            state.grades = payload
        }
    },
}

const actions = {
    async reloadGrades({ commit }) {
        const grades = await GradeService.loadGrades()
        commit('reloadGrades', grades)
    },
    async deleteGrade({ commit }, payload) {
        await GradeService.deleteGrade(payload)
        const grades = await GradeService.loadGrades()
        commit('reloadGrades', grades)
    },
    async editGrade({ commit }, payload) {
        await GradeService.editGrade(payload)
        const grades = await GradeService.loadGrades()
        commit('reloadGrades', grades)
    },
    async createGrade({ commit }, payload) {
        await GradeService.createGrade(payload)
        const grades = await GradeService.loadGrades()
        commit('reloadGrades', grades)
    },
}

export default {
    state,
    getters,
    mutations,
    actions
}
