import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import GAuth from 'vue-google-oauth2'

Vue.config.productionTip = false
const gauthOption = {
  
  // clientId: process.env.VUE_APP_GOOGLE_AUTH_CLIENT_ID,
  clientId: "1000989328813-6goarcmgrimea9uko5tj3rejmg0pnk6v.apps.googleusercontent.com",
  scope: 'profile email',
  prompt: 'consent',
  plugin_name: "chat",
}
Vue.use(GAuth, gauthOption)
new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
