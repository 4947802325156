import Vue from 'vue'
import Router from 'vue-router'
import MainView from "@/views/MainView"
import LoginView from "@/views/LoginView"

import UsersView from "@/views/User/UsersView"

import ProfilesView from "@/views/Profile/ProfilesView"
import ProfileEditView from "@/views/Profile/ProfileEditView"
import ProfileCreateView from "@/views/Profile/ProfileCreateView"

import OrganizationsView from "@/views/Organization/OrganizationsView"
import OrganizationEditView from "@/views/Organization/OrganizationEditView"
import OrganizationCreateView from "@/views/Organization/OrganizationCreateView"

import GradesView from "@/views/Entities/GradesView"
import CitiesView from "@/views/Entities/CitiesView"
import OrganizationStatusesView from "@/views/Entities/OrganizationStatusesView"

import EntitiesView from "@/views/Entities/EntitiesView"
import AuthService from "@/services/AuthService"


Vue.use(Router)


const router = new Router({
    mode: 'history',
    routes: [
        { path: '/', name:'main', component: MainView, children: [
            { path: 'users', name: "users", component: UsersView },

            { path: 'profiles', name: "profiles", component: ProfilesView },
            { path: 'profile_edit/:id', name: "profile_edit", component: ProfileEditView },
            { path: 'profile_create', name: "profile_create", component: ProfileCreateView },
            
            { path: 'organizations', name: "organizations", component: OrganizationsView },
            { path: 'organization_edit/:id', name: "organization_edit", component: OrganizationEditView },
            { path: 'organization_create', name: "organization_create", component: OrganizationCreateView },

            { path: 'entities', name: "entities", component: EntitiesView, children: [
                { path: 'grades', name: "grades", component: GradesView },
                { path: 'cities', name: "cities", component: CitiesView },
                { path: 'organization_statuses', name: "organization_statuses", component: OrganizationStatusesView },
            ] },
        ] },            
        { path: '/login', name:'login', component: LoginView, meta: {public: true}},
        // { path: "/:catchAll(.*)", name: "NotFound", component: NotFound },
    ]
})

router.beforeEach((to, from, next)=>{
    const isLoggedIn = AuthService.isLoggedIn();
    const isPublicPage = to.matched.some(record => record.meta.public)
    
    if (isLoggedIn && to.name == 'login'){
        next({ name: 'main' })
    }

    if (isLoggedIn || isPublicPage){
        next();
    } else {
        next({ name: 'login' })
    }
})

export default router