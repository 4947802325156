const ENDPOINTS = {
  // Auth
  LOGIN_BY_PIN: '/auth/login_by_pin',
  LOGIN_BY_GOOGLE_AUTH: '/auth/login_by_google_auth',
  REFRESH_TOKEN: '/auth/refresh_token',

  // Store Orders
  PROFILE: '/profile/',
  PROFILE_IMAGE: '/profile_image/',
  UPLOAD_PROFILE_IMAGE: '/profile/upload_image/',
  REQUESTS: '/user/requests/',
  USER: '/user/',
  FORCE_UPDATE_USERS: '/user/force_update/',
  GRADE: '/grade/',
  CITY: '/city/',
  SEX: '/sex/',
  ORGANIZATION: '/organization/',
  ORGANIZATION_STATUS: '/organization_status/',
};

export default ENDPOINTS