<template>
  <v-app>
    <v-main>
      <v-container fluid>
        <router-view></router-view>
      </v-container>
    </v-main>
    <bottom-navigation v-if="isLoggedIn"></bottom-navigation>
    <v-overlay :value="isLoading">
      <v-progress-circular indeterminate size="64"/>
    </v-overlay>
  </v-app>
</template>


<script>
import AuthService from "@/services/AuthService"
import BottomNavigation from '@/components/BottomNavigation.vue'

export default {
  name: 'App',
  components: { BottomNavigation },

  data: () => ({
    active: true
  }),
  computed: {
    isLoggedIn(){
      return AuthService.isLoggedIn();
    },
    isLoading(){
      return this.$store.getters.isLoading
    }
  }  
};
</script>
