<template>
  <div>
    <v-data-table :headers="requestHeaders" :items="users" sort-by="id" :sort-desc="true" class="elevation-1">
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>Users</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <v-btn color="error" @click="forceUpdateDialog = ! forceUpdateDialog">Force update</v-btn>

          <v-dialog v-model="approveDialog" max-width="500px">
            <v-card>
              <v-card-title class="text-h5">Approve Request</v-card-title>
              <v-card-text>
                <v-container>
                  <v-autocomplete
                    auto-select-first
                    clearable
                    v-model="seletedProfileId"
                    :items="profiles"
                    :item-text="item => item.last_name_en + ' ' + item.first_name_en"
                    :item-value="item => item.id">
                  </v-autocomplete>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" @click="aproveConfirm">Approve</v-btn>
                <v-btn color="secondary" @click="closeApprove">Cancel</v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <v-dialog v-model="rejectDialog" max-width="500px">
            <v-card>
              <v-card-title class="text-h6">Are you sure you want to reject this request?</v-card-title>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="error" @click="rejectConfirm">Reject</v-btn>
                <v-btn color="secondary" @click="closeReject">Cancel</v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <v-dialog v-model="deleteDialog" max-width="500px">
            <v-card>
              <v-card-title class="text-h6">Are you sure you want to delete this user?</v-card-title>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="error" @click="deleteConfirm">Delect</v-btn>
                <v-btn color="secondary" @click="closeDelete">Cancel</v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <v-dialog v-model="forceUpdateDialog" max-width="500px">
            <v-card>
              <v-card-title class="text-h6">Are you sure you want to force update user?</v-card-title>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="error" @click="forceUpdateConfirm">Confirm Force Update</v-btn>
                <v-btn color="secondary" @click="forceUpdateDialog = ! forceUpdateDialog">Cancel</v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>          
        </v-toolbar>
      </template>
      <template v-slot:[`item.profile`]="{ item }">{{getFullName(item)}}</template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-icon @click="approveRequest(item)">mdi-file-document-check-outline</v-icon>
        <v-icon @click="rejectRequest(item)">mdi-file-document-remove-outline</v-icon>
        <v-icon @click="deleteUser(item)">mdi-delete</v-icon>
      </template>
    </v-data-table>
  </div>
</template>


<script>
export default {
  name: 'profiles-view',
  data: () => ({
    seletedProfileId: null,
    selectedUser: null,

    approveDialog: false,
    rejectDialog: false,
    deleteDialog: false,
    forceUpdateDialog: false,

    requestHeaders: [
      { text: 'Id', value: 'id', align: 'start'},
      { text: 'Name', value: 'name'},
      { text: 'Given', value: 'given_name'},
      { text: 'Family', value: 'family_name'},
      { text: 'Email', value: 'email'},
      { text: 'Request', value: 'requested_profile_name'},
      { text: 'Profile', value: 'profile'},
      { text: '', value: 'actions' },
    ]
  }),

  computed: {
    users(){
      return this.$store.getters.users
    },
    profiles(){
      return this.$store.getters.profiles
    }
  },

  watch: {
    deleteDialog (val) {
      val || this.closeDelete()
    },
    approveDialog (val) {
      val || this.closeApprove()
    },
    rejectDialog (val) {
      val || this.closeReject()
    }
  },

  created () {
    this.initialize()
  },

  methods: {
    initialize () {
      this.$store.dispatch("reloadUsers")
      this.$store.dispatch("reloadProfiles")
    },

    getFullName(item){
      return (item.profile) ? (item.profile.last_name_en + ' ' + item.profile.first_name_en) : "-----"
    },

    // Approve Request dialog
    approveRequest(item){
      this.selectedUser = Object.assign({}, item)
      this.approveDialog = true
    },
    aproveConfirm() {
      const user_id = this.selectedUser ? this.selectedUser.id : -1
      let payLoad = {
        user_id: user_id,
        profile_id: this.seletedProfileId
      }
      this.$store.dispatch("approveRequest", payLoad)
      this.closeApprove()
    },
    closeApprove() {
      this.approveDialog = false
      this.$nextTick(() => {this.selectedUser = null})
    },

    // Reject Request dialog
    rejectRequest(item){
      this.selectedUser = Object.assign({}, item)
      this.rejectDialog = true
    },
    rejectConfirm() {
      const user_id = this.selectedUser ? this.selectedUser.id : -1
      this.$store.dispatch("rejectRequest", user_id)
      this.closeReject()
    },
    closeReject() {
      this.rejectDialog = false
      this.$nextTick(() => {this.selectedUser = null})
    },    

    // Delete User dialog
    deleteUser(item){
      this.selectedUser = Object.assign({}, item)
      this.deleteDialog = true
    },
    deleteConfirm() {
      const user_id = this.selectedUser ? this.selectedUser.id : -1
      this.$store.dispatch("deleteUser", user_id)
      this.closeDelete()
    },
    closeDelete() {
      this.deleteDialog = false
      this.$nextTick(() => {this.selectedUser = null})
    },

    // Force Update
    forceUpdateConfirm() {
      this.$store.dispatch("forceUpdateUsers")
      this.forceUpdateDialog = !this.forceUpdateDialog
    },
  },
}
</script>