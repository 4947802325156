import HttpService from '@/services/HttpService'
import ENDPOINTS from '@/services/endpoints'


class OrganizationStatusService {
  loadOrganizationStatuses = async () => { return (await HttpService.client.get(ENDPOINTS.ORGANIZATION_STATUS)).data };
  deleteOrganizationStatus = async (payload) => { await HttpService.client.delete(ENDPOINTS.ORGANIZATION_STATUS + payload) };
  createOrganizationStatus = async (payload) => { await HttpService.client.post(ENDPOINTS.ORGANIZATION_STATUS, payload) }; 
  editOrganizationStatus = async (payload) => { await HttpService.client.put(ENDPOINTS.ORGANIZATION_STATUS + payload.id, payload) }; 
}

const organizationStatusService = new OrganizationStatusService();

export default organizationStatusService;