import ProfileService from "@/services/ProfileService"

const state = {
    currentProfile: null,
    profiles: [],
  }
  
const getters = {
    currentProfile(state) { return state.currentProfile },
    profiles(state) { return state.profiles },
}

const mutations = {
    reloadProfiles(state, payload) {
        if (payload) {
            state.profiles = payload
        }
    },
    updateCurrentProfiles(state, payload) {
        if (payload) {
            state.currentProfile = payload
        }
    },      
}

const actions = {
    async reloadProfiles({ commit }) {
        const profiles = await ProfileService.loadProfiles()
        commit('reloadProfiles', profiles)
    },
    async loadProfile({ commit }, payload) {
        const currentProfile = await ProfileService.loadProfile(payload)
        commit('updateCurrentProfiles', currentProfile)
        return currentProfile
    },
    async deleteRequest({ dispatch }, payload) {
        await ProfileService.deleteRequest(payload)
        dispatch('reloadProfiles')
    },
    async deleteProfile({ dispatch }, payload) {
        await ProfileService.deleteProfile(payload)
        dispatch('reloadProfiles')
    },
    async editProfile({ dispatch }, payload) {
        await ProfileService.editProfile(payload)
        dispatch('reloadProfiles')
    },
    async createProfile({ dispatch }, payload) {
        await ProfileService.createProfile(payload)
        dispatch('reloadProfiles')
    },
}

export default {
    state,
    getters,
    mutations,
    actions
}
