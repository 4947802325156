<template>
  <v-bottom-navigation>
    <v-btn :to="{name: 'main'}"><span>Main</span><v-icon>mdi-badge-account-outline</v-icon></v-btn>
    <v-btn :to="{name: 'users'}"><span>Users</span><v-icon>mdi-badge-account-outline</v-icon></v-btn>
    <v-btn :to="{name: 'profiles'}"><span>Profiles</span><v-icon>mdi-account-box-multiple</v-icon></v-btn>
    <v-btn :to="{name: 'organizations'}"><span>Organizations</span><v-icon>mdi-home-group</v-icon></v-btn>
    <v-btn :to="{name: 'entities'}"><span>Entities</span><v-icon>mdi-folder-multiple</v-icon></v-btn>
    <v-btn v-on:click="logout"><span>Logout</span><v-icon>mdi-logout</v-icon></v-btn>

  </v-bottom-navigation>
</template>

<script>
  export default {
    data: () => ({ value: 1 }),


    methods: {
      logout(){
        this.$store.dispatch('logout').then(()=>{ this.$router.push({ name: 'login'}) })
      },
    }
  }
</script>