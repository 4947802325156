<template>
  <v-container>
    <v-form ref="draftStoreItemForm" v-model="valid" @submit.prevent="createEntity">
      <v-row>
        <v-col cols="12" sm="5" md="5">
          <v-text-field :rules="name_original_rules" required v-model="name_original" label="Original Name"/>
        </v-col>
        <v-col cols="12" sm="5" md="5">
          <v-text-field :rules="name_translated_rules" required v-model="name_translated" label="Translated Name"/>
        </v-col>
        <v-col cols="12" sm="2" md="2">
          <v-select :rules="[v => !!v || 'Item is required']" v-model="store_item_type_id" :items="storeItemTypes" item-text="name" item-value="id" label="Type" />
        </v-col>
        <v-col cols="12" sm="12" md="12">
          <v-textarea :rules="description_rules" required v-model="description" label="Description"/>
        </v-col>        
      </v-row>
      <v-btn color="primary" :disabled="!valid" type="submit">Create</v-btn>
      <v-btn color="secondary" :to="{name: 'organizations'}">Cancel</v-btn>
      
    </v-form>
  </v-container>
</template>
  
<script>
  export default {
    name: "createDraftStoreItem",
    data () {
      return {
        valid : false,
        name_original: null,
        name_original_rules: [
          v => !!v || 'Original name is required',
          v => (v && v.length <= 255) || 'Original name must be less than 255 characters',
        ],
        name_translated: null,
        name_translated_rules: [
          v => !!v || 'Tranlsated name is required',
          v => (v && v.length <= 255) || 'Tranlsated name must be less than 255 characters',
        ],        
        description: null,
        description_rules: [
          v => !!v || 'Description is required',
          v => (v && v.length <= 2048) || 'Description must be less than 2048 characters',
        ],       
        flavours: [],
        sort_info_id: null,
        roasting_level_id: null,
        origin_country_id: null,
        processing_info_id: null,
        store_item_type_id: null,
        pack_weight: null,
        pack_price: null,
        expect_description: null,
        expect_description_rules: [
          v => !!v || 'Excpected description is required',
          v => (v && v.length <= 2048) || 'Excpected description must be less than 2048 characters',
        ],
        main_image_id: null,
        carusel_image_ids: [],
        flavourColors: ['green', 'purple', 'indigo', 'cyan', 'teal', 'orange'],
      }
    },
    computed: {
      flavourItems(){
        var flavourItems = []
        flavourItems.push({ header: 'Select an flavour option or create one' })
        for (var flavour of this.$store.getters.flavours){
          flavourItems.push({
            id: flavour.id,
            name: flavour.name,
            color: this.flavourColors[ flavourItems.length % this.flavourColors.length ]
          })
        }
        return flavourItems        
      },
      sortInfos(){
        return this.$store.getters.sortInfos
      },
      storeItemTypes(){
        return this.$store.getters.storeItemTypes
      },
      processingInfos(){
        return this.$store.getters.processingInfos
      },
      roastingLevels(){
        return this.$store.getters.roastingLevels
      },
      countries(){
        return this.$store.getters.countries
      },      
    },
    methods: {
      initialize () {
        this.$store.dispatch("reloadAllFlavours")
        this.$store.dispatch("reloadAllCountries")
        this.$store.dispatch("reloadAllSortInfos")
        this.$store.dispatch("reloadAllProcessingInfos")
        this.$store.dispatch("reloadAllRoastingLevels")
        this.$store.dispatch("reloadAllStoreItemTypes")
      },
      createEntity(){
        this.$refs.draftStoreItemForm.validate()
        if (this.valid){
          const flavour_ids = []
          for(var flavour of this.flavours) {
            flavour_ids.push(flavour.id)
          }
          const entity = {
            name_original: this.name_original,
            name_translated: this.name_translated,
            description: this.description,
            flavour_ids: flavour_ids,
            sort_info_id: this.sort_info_id,
            roasting_level_id: this.roasting_level_id,
            origin_country_id: this.origin_country_id,
            pack_weight: parseInt(this.pack_weight),
            pack_price: parseInt(this.pack_price),
            expect_description: this.expect_description,
            processing_info_id: this.processing_info_id,
            store_item_type_id: this.store_item_type_id,
          }
          this.$store.dispatch('createDraftStoreItem',  entity).then(() => {
            this.$router.push({ name: 'organizations'})
          })
        }
      },
    },
    created() {
      this.initialize()
    }
  }
</script>
  
