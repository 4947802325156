<template>
  <v-container>
    <v-row>
      <v-col>
        <pin></pin>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="text-center">
        - or -
      </v-col>
    </v-row>    
    <v-row>
      <v-col>
        <google-auth></google-auth>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Pin from '@/components/auth/Pin.vue'
import GoogleAuth from '@/components/auth/GoogleAuth.vue'

export default {
  components: { Pin, GoogleAuth },
  name: 'loginView'
}
</script>