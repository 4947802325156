import OrganizationStatusService from "@/services/OrganizationStatusService"

const state = {
    organizationStatuses: [],
  }
  
const getters = {
    organizationStatuses(state) { return state.organizationStatuses },
}

const mutations = {
    reloadOrganizationStatuses(state, payload) {
        if (payload) {
            state.organizationStatuses = payload
        }
    },
}

const actions = {
    async reloadOrganizationStatuses({ commit }) {
        const organizationStatuses = await OrganizationStatusService.loadOrganizationStatuses()
        commit('reloadOrganizationStatuses', organizationStatuses)
    },
    async deleteOrganizationStatus({ commit }, payload) {
        await OrganizationStatusService.deleteOrganizationStatus(payload)
        const organizationStatuses = await OrganizationStatusService.loadOrganizationStatuses()
        commit('reloadOrganizationStatuses', organizationStatuses)
    },
    async editOrganizationStatus({ commit }, payload) {
        await OrganizationStatusService.editOrganizationStatus(payload)
        const organizationStatuses = await OrganizationStatusService.loadOrganizationStatuses()
        commit('reloadOrganizationStatuses', organizationStatuses)
    },
    async createOrganizationStatus({ commit }, payload) {
        await OrganizationStatusService.createOrganizationStatus(payload)
        const organizationStatuses = await OrganizationStatusService.loadOrganizationStatuses()
        commit('reloadOrganizationStatuses', organizationStatuses)
    },
}

export default {
    state,
    getters,
    mutations,
    actions
}
