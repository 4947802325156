import HttpService from '@/services/HttpService'
import ENDPOINTS from '@/services/endpoints'


class ProfileService {
  approveRequest = async (payload) => { await HttpService.client.post(ENDPOINTS.APPROVE_REQUESTS, payload) }; 
  rejectRequest = async (payload) => { await HttpService.client.delete(ENDPOINTS.REQUESTS + payload) };

  loadStoreOrders = async () => {return await HttpService.client.get(ENDPOINTS.STORE_ORDERS)}
  loadStoreOrder = async (payload) => {return await HttpService.client.get(ENDPOINTS.STORE_ORDERS + payload)}

  loadProfile = async (payload) => { return (await HttpService.client.get(ENDPOINTS.PROFILE + payload)).data };
  loadProfiles = async () => { return (await HttpService.client.get(ENDPOINTS.PROFILE)).data };
  deleteProfile = async (payload) => { await HttpService.client.delete(ENDPOINTS.PROFILE + payload) };
  createProfile = async (payload) => { await HttpService.client.post(ENDPOINTS.PROFILE, payload) }; 
  editProfile = async (payload) => { await HttpService.client.put(ENDPOINTS.PROFILE + payload.id, payload) };
}

const profileService = new ProfileService();

export default profileService;