<template>
  <v-card>
    <v-tabs background-color="accent-4" center-active>
      <v-tab :to="{name: 'grades'}">Grades</v-tab>
      <v-tab :to="{name: 'organization_statuses'}">Statuses</v-tab>
      <v-tab :to="{name: 'cities'}">Cities</v-tab>
    </v-tabs>
    <v-container fluid>
        <router-view></router-view>
    </v-container>      
  </v-card>
</template>

<script>
export default {
  name: 'admin-panel-view',
}

</script>