import OrganizationService from "@/services/OrganizationService"

const state = {
    organizations: [],
  }
  
const getters = {
    organizations(state) { return state.organizations },
}

const mutations = {
    reloadOrganizations(state, payload) {
        if (payload) {
            state.organizations = payload
        }
    },
}

const actions = {
    async reloadOrganizations({ commit }) {
        const organizations = await OrganizationService.loadOrganizations()
        commit('reloadOrganizations', organizations)
    },
    async deleteOrganization({ commit }, payload) {
        await OrganizationService.deleteOrganization(payload)
        const organizations = await OrganizationService.loadOrganizations()
        commit('reloadOrganizations', organizations)
    },
    async editOrganization({ commit }, payload) {
        await OrganizationService.editOrganization(payload)
        const organizations = await OrganizationService.loadOrganizations()
        commit('reloadOrganizations', organizations)
    },
    async createOrganization({ commit }, payload) {
        await OrganizationService.createOrganization(payload)
        const organizations = await OrganizationService.loadOrganizations()
        commit('reloadOrganizations', organizations)
    },
}

export default {
    state,
    getters,
    mutations,
    actions
}
