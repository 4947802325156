<template>
  <v-data-table :headers="headers" :items="organizations" sort-by="id" :sort-desc="true" class="elevation-1">
    <template v-slot:top>
      <v-toolbar flat>
        <v-toolbar-title>Organizations</v-toolbar-title>
        <v-divider class="mx-4" inset vertical></v-divider>
        <v-spacer></v-spacer>
        <router-link :to="{name: 'organization_create'}"><v-btn color="primary" dark class="mb-2" >New Organization</v-btn></router-link>
        <v-dialog v-model="dialogDelete" max-width="500px">
          <v-card>
            <v-card-title class="text-h6">Are you sure you want to delete this Organization?</v-card-title>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="error" @click="deleteItemConfirm">Delete</v-btn>
              <v-btn color="secondary" @click="closeDelete">Cancel</v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>
    <template v-slot:[`item.organization_status`]="{ item }">
      {{ item.organization_status.name }}
    </template>
    <template v-slot:[`item.actions`]="{ item }">
      <v-btn icon :to="{ name: 'organization_edit', params: { id: item.id }}" >
        <v-icon small class="mr-2">mdi-pencil</v-icon>
      </v-btn>
      <v-icon small @click="deleteItem(item)">mdi-delete</v-icon>
    </template>
    <template v-slot:no-data>
      <v-btn color="primary" @click="initialize">Reset</v-btn>
    </template>
  </v-data-table>
</template>


<script>
export default {
  name: 'organizations-view',
  data: () => ({
    dialogDelete: false,
    currentItem: null,
    headers: [
      { text: 'Id', value: 'id', align: 'start'},
      { text: 'EKF Id', value: 'ekf_id'},
      { text: 'Name UA', value: 'name_ua'},
      { text: 'Name EN', value: 'name_en'},
      { text: 'City', value: 'city'},
      { text: 'Status', value: 'organization_status'},
      { text: '', value: 'actions' },
    ],
  }),

  computed: {
    organizations(){
      return this.$store.getters.organizations
    },
  },

  watch: {
    dialog (val) {
      val || this.close()
    },
    dialogDelete (val) {
      val || this.closeDelete()
    },
  },

  created () {
    this.initialize()
  },

  methods: {
    initialize () {
      this.$store.dispatch("reloadOrganizations")
    },

    deleteItem(item) {
      this.currentItem = item
      this.dialogDelete = true
    },

    deleteItemConfirm () {
      const currentItemIndex = this.currentItem ? this.currentItem.id : -1
      this.$store.dispatch("deleteOrganization", currentItemIndex)
      this.closeDelete()
    },

    closeDelete() {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.currentItem = null
      })
    },
  },
}
</script>