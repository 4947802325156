import Vue from 'vue'
import Vuex from 'vuex'
import AuthStore from '@/store/modules/AuthStore'
import GradeStore from '@/store/modules/GradeStore'
import CityStore from '@/store/modules/CityStore'
import OrganizationStatusStore from '@/store/modules/OrganizationStatusStore'
import OrganizationStore from '@/store/modules/OrganizationStore'
import ProfileStore from '@/store/modules/ProfileStore'
import ProfileImageStore from '@/store/modules/ProfileImageStore'
import UserStore from '@/store/modules/UserStore'
import SexStore from '@/store/modules/SexStore'


Vue.use(Vuex)
const store = new Vuex.Store({
    modules: {
        AuthStore,
        GradeStore,
        CityStore,
        OrganizationStatusStore,
        OrganizationStore,
        ProfileStore,
        ProfileImageStore,
        UserStore,
        SexStore,
    }
});

export default store