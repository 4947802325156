<template>
  <div>
    <v-data-table :headers="requestHeaders" :items="profiles" sort-by="id" :sort-desc="true" class="elevation-1" @click:row="editProfile">
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>Profiles</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <router-link :to="{name: 'profile_create'}"><v-btn color="primary" dark class="mb-2" >New Profile</v-btn></router-link>
          <v-dialog v-model="dialogRequestDelete" max-width="500px">
            <v-card>
              <v-card-title class="text-h6">Are you sure you want to delete this Request?</v-card-title>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="error" @click="deleteRequestConfirm">Delete</v-btn>
                <v-btn color="secondary" @click="closeDeleteRequest">Cancel</v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:[`item.organization`]="{ item }">
        {{ item.organization.name_ua }}
      </template>
      <template v-slot:[`item.kendo_grade`]="{ item }">
        {{ item.current_kendo_grade.name_ua }}
      </template>
      <template v-slot:[`item.full_name`]="{ item }">
        <v-avatar size="36px">
          <img v-if="item.profile_image" alt="Avatar" :src="item.profile_image.url">
          <v-icon v-else>mdi-account-circle</v-icon>
        </v-avatar>
        {{ item.first_name_en + " " + item.last_name_en }}
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-icon small @click="deleteRequest(item)">mdi-delete</v-icon>
      </template>
    </v-data-table>
  </div>
</template>

<script>
export default {
  name: 'profiles-view',
  data: () => ({
    seletedProfile: null,
    approveDialog: false,
    dialogRequestDelete: false,
    currentRequest: null,
    requestHeaders: [
      { text: 'Id', value: 'id', align: 'start'},

      { text: 'Full name', value: 'full_name'},
      { text: 'Email', value: 'email'},
      { text: 'Dan', value: 'kendo_grade'},
      { text: 'Organization', value: 'organization'},
      { text: '', value: 'actions' },
    ]
  }),

  computed: {
    profiles(){
      return this.$store.getters.profiles
    }
  },

  watch: {
    dialogRequestDelete (val) {
      val || this.closeDeleteRequest()
    },
  },

  created () {
    this.initialize()
  },

  methods: {
    initialize () {
      this.$store.dispatch("reloadProfiles")
    },
    getFullName(item){
      if (item.profile) {
        return item.last_name_en + ' ' + item.first_name_en
      } else {
        return "-----"
      }
    },
    deleteRequest(item) {
      this.currentRequest = item
      this.dialogRequestDelete = true
    },
    deleteRequestConfirm () {
      const currentRequestIndex = this.currentRequest ? this.currentRequest.id : -1
      this.$store.dispatch("deleteRequest", currentRequestIndex)
      this.closeDeleteRequest()
    },
    closeDeleteRequest() {
      this.dialogRequestDelete = false
      this.$nextTick(() => {
        this.currentRequest = null
      })
    },
    editProfile(item) {
      this.$router.push({ name: 'profile_edit', params: { id: item.id }})
    },    
  },
}
</script>