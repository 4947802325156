import ProfileImageService from "@/services/ProfileImageService"


const actions = {
    async uploadProfileImage(context, payload) {
        console.log("store action. payload:", payload)
        await ProfileImageService.uploadProfileImage(payload)
    },
    async deleteProfileImage(context, payload) {
        await ProfileImageService.deleteProfileImage(payload)
    },    
}

export default {
    actions
}
