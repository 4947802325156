<template>
    <div class="text-center">
        <v-btn v-on:click="signIn">
            <v-icon>mdi-google</v-icon>
            Sign in with Google
        </v-btn>
    </div>
</template>

<script>
export default {
    data: function () {
        return {
            response: ''
        }
    },    
    methods: {
        async signIn() {
            const authCode = await this.$gAuth.getAuthCode()
            const payload = {
                "auth_code": authCode
            }
            this.$store.dispatch('loginByGoogleAuth', payload).then(() =>{ this.$router.push({ name: 'orders'}) })
        },
    }
}
</script>