import AuthService from "@/services/AuthService"

const USER_KEY = "user"
function isJsonString(str) {
  try {
      JSON.parse(str);
  } catch (e) {
      return false;
  }
  return true;
}

const state = {
  loading: false,
  user: (localStorage.getItem(USER_KEY) && isJsonString(localStorage.getItem(USER_KEY))) ? JSON.parse(localStorage.getItem(USER_KEY)) : {},
  error: ""
}

const getters = {
  getUser(state) { return state.user },
  isLoading(state) {return state.loading },
}

const mutations = {
  setUser(state, user) {
    state.user = user
    localStorage.setItem('user', JSON.stringify(user));
  },
  logout(state) {
    state.user = {}
    localStorage.clear();
  },
  loading(state, loadingState){
    state.loading = loadingState
  }
}

const actions = {
  async loginByPin({commit}, payload) {
    const user = await AuthService.loginByPin(payload)
    commit("setUser", user)
  },
  async loginByGoogleAuth({commit}, payload) {
    const user = await AuthService.loginByGoogleAuth(payload)
    commit("setUser", user)
  },
  async logout({commit}) {
    commit("logout")
  },
}

export default {
    state,
    getters,
    mutations,
    actions
}