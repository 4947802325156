import HttpService from '@/services/HttpService'
import ENDPOINTS from '@/services/endpoints'


class UserService {
  approveRequest = async (payload) => { await HttpService.client.post(ENDPOINTS.REQUESTS, payload) }; 
  rejectRequest = async (payload) => { await HttpService.client.delete(ENDPOINTS.REQUESTS + payload) };
  
  loadUsers = async () => { return (await HttpService.client.get(ENDPOINTS.USER)).data };
  deleteUser = async (payload) => { await HttpService.client.delete(ENDPOINTS.USER + payload) };
  createUser = async (payload) => { await HttpService.client.post(ENDPOINTS.USER, payload) }; 
  editUser = async (payload) => { await HttpService.client.put(ENDPOINTS.USER + payload.id, payload) };

  forceUpdateUsers = async () => { await HttpService.client.post(ENDPOINTS.FORCE_UPDATE_USERS) };
}

const userService = new UserService();

export default userService;