import UserService from "@/services/UserService"

const state = {
    users: [],
  }
  
const getters = {
    users(state) { return state.users },
}

const mutations = {
    reloadUsers(state, payload) {
        if (payload) {
            state.users = payload
        }
    },
}

const actions = {
    async reloadUsers({ commit }) {
        const users = await UserService.loadUsers()
        commit('reloadUsers', users)
    },
    async deleteUser({ dispatch }, payload) {
        await UserService.deleteUser(payload)
        dispatch('reloadUsers')
    },
    async editUser({ dispatch }, payload) {
        await UserService.editUser(payload)
        dispatch('reloadUsers')
    },
    async createUser({ dispatch }, payload) {
        await UserService.createUser(payload)
        dispatch('reloadUsers')
    },

    async approveRequest({ dispatch }, payload){
        await UserService.approveRequest(payload)
        dispatch('reloadUsers')
    },
    async rejectRequest({ dispatch }, payload) {
        await UserService.rejectRequest(payload)
        dispatch('reloadUsers')
    },
    async forceUpdateUsers({dispatch}){
        await UserService.forceUpdateUsers()
        dispatch('reloadUsers')
    }
}

export default {
    state,
    getters,
    mutations,
    actions
}
